import React from "react";
import hero from "../assets/Hero/Manos.png";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <div name="inicio" className="w-full h-screen flex flex-col mx-auto ">
      <img src={hero} alt="" className="w-full h-screen object-cover" />
      <div className="absolute w-full h-screen top-0 left-0 bg-gray-900/50"></div>
      <div className="absolute w-full bottom-10 text-white text-center mx-auto items-center">
        <div className="flex flex-col px-2 lg:max-w-[50%] md:max-w-[70%] sm:w-full  text-white">
          <h4 className="py-3 lg:text-[35px] text-start md:text-[25px] sm:text-[18px]  font-sans font-bold ">
            ¿Tenés que alquilar un local y/o inmuebles y necesitás garantías?
          </h4>
        </div>
        <div className="flex flex-col px-2 my-12 lg:max-w-[50%] md:max-w-[70%] float-right  text-white">
          <h4 className="py-3 lg:text-[35px] text-end md:text-[25px] sm:text-[18px]  font-sans font-bold ">
            ¿Querés aumentar tus ingresos trabajando tu recibo de sueldo?
          </h4>
        </div>
        <div className="flex flex-col w-full text-center my-4">
          <h1 className="lg:text-[45px] md:text-[40px] sm:text-[25px]">
            ¡NOSOTROS TE DAMOS LA SOLUCIÓN!
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
