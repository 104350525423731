import React from "react";

const PreguntasFrecuentes = () => {
  return (
    <div
      name="preguntasFrecuentes"
      className="w-full text-black bg-[#f9f9f9] font-sans py-[50px] pt-[96px] space-y-4"
    >
      <h3 className="sm:px-[1rem] lg:px-[3rem] lg:text-[30px] md:text-[30px] sm:text-[20px] font-bold">
        Preguntas Frecuentes
      </h3>
      <div className="max-w-[1240px] md:flex gap-x-16 mx-auto">
        <div className="flex flex-col justify-center w-full mt-4">
          <h3 className="sm:px-[1rem] lg:px-[3rem] lg:text-[20px] md:text-[20px] sm:text-[15px] font-bold my-2">
            Alquilar garantía
          </h3>
          <details className="sm:px-[1rem] lg:px-[3rem] w-full mb-6">
            <summary className="text-[20px] sm:text-[14px]">
              ¿Tenés recibos de sueldo para alquilar local o inmueble?
            </summary>
            <div className="g">
              <p className="text-[14px] py-3">
                Sí. La cantidad de recibos que necesites de diferentes montos,
                con más de <strong>5 años</strong> de antigüedad.
              </p>
            </div>
          </details>
          <details className="sm:px-[1rem] lg:px-[3rem] w-full mb-6">
            <summary className="text-[20px] sm:text-[14px]">
              ¿Son seguros, reales?
            </summary>
            <div className="g">
              <p className="text-[14px] py-3">
                Sí, son recibos de sueldo de trabajadores públicos y privados,
                <strong> sin seven ni veraz</strong>, avalados por abogados de
                la S.R.L.
              </p>
            </div>
          </details>
          <details className="sm:px-[1rem] lg:px-[3rem] w-full mb-6">
            <summary className="text-[20px] sm:text-[14px]">
              ¿Cuánto cuesta alguilar un recibo de sueldo?
            </summary>
            <div className="g">
              <p className="text-[14px] py-3">
                Varía <strong>según el monto</strong> del recibo de sueldo.
              </p>
            </div>
          </details>
        </div>
        <div className="flex flex-col justify-center w-full mt-4">
          <h3 className="sm:px-[1rem] lg:px-[3rem] lg:text-[20px] md:text-[20px] sm:text-[15px] font-bold my-2">
            Trabajar recibo de sueldo
          </h3>
          <details className="sm:px-[1rem] lg:px-[3rem] w-full mb-6">
            <summary className="text-[20px] sm:text-[14px]">
              ¿Qué debo hacer para trabajar mi recibo de sueldo?
            </summary>
            <div className="g">
              <p className="text-[14px] py-3">
                Tener 2 años de antigüedad mínima, sin figurar en seven ni
                veraz. Presentar:{" "}
                <strong>
                  Copia del DNI de ambos lados, constatación de domicilio, mail
                  y los 3 últimos recibos de sueldo actualizados
                </strong>
                .
              </p>
            </div>
          </details>
          <details className="sm:px-[1rem] lg:px-[3rem] w-full mb-6">
            <summary className="text-[20px] sm:text-[14px]">
              ¿Qué seguridad me brinda la empresa?
            </summary>
            <div className="g">
              <p className="text-[14px] py-3">
                Se firma un contrato mutuo, en el que se específica que, ante un
                problema, la S.R.L asume el <strong>100%</strong> de la
                responsabilidad.
              </p>
            </div>
          </details>
          <details className="sm:px-[1rem] lg:px-[3rem] w-full mb-6">
            <summary className="text-[20px] sm:text-[13.5px]">
              ¿Cuánto y cuándo cobro al firmar como garante?
            </summary>
            <div className="g">
              <p className="text-[14px] py-3">
                <strong>Cobra en efectivo</strong> al momento de firmar. El
                monto dependerá del recibo de sueldo.
              </p>
            </div>
          </details>
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
