import React from "react";
import securityImg from "../assets/SecuritySec/Firm.png";
const Security = () => {
  return (
    <div id="testimonial" className="  text-white  ">
      <div class="container mx-auto grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 items-center gap-y-3">
        <div className="space-y-3 grayscale">
          <h4 className="font-semibold lg:text-[16px] md:text-[16px] sm:text-[14px]">
            Seguridad garantizada
          </h4>
          {/* <p className="lg:text-[16px] md:text-[16px] sm:text-[13px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}
        </div>
        <div className="space-y-3 grayscale">
          <h4 className="font-semibold lg:text-[16px] md:text-[16px] text-[16px] sm:text-[14px]">
            100% de Responsabilidad
          </h4>
          {/* <p className="text-[16px] smlg:text-[16px] md:text-[16px] sm:text-[13px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}
        </div>
        <div className="space-y-3">
          <h4 className="font-semibold lg:text-[16px] md:text-[16px] text-[16px] sm:text-[14px]">
            Recibos de sueldos confiables
          </h4>
          {/* <p className="text-[16px] lg:text-[16px] md:text-[16px] sm:text-[13px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Security;
