import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { Link } from "react-scroll";
import logoNav from "../assets/Navbar/Logo.png";
import facebook from "../assets/Footer/facebook.png";
import insta from "../assets/Footer/instagram.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [logo, setLogo] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [mode, setMode] = useState(true);
  const [toggleBtn, setToggleBtn] = useState(
    '<i class="far fa-sun"></i>Light Mode'
  );
  const [navbar, setNavbar] = useState(false);

  const handleNav = () => {
    setNav(!nav);
    setLogo(!logo);
  };

  const navScroll = () => {
    setScroll(!scroll);
  };

  const toggleDarkMode = () => {
    if (mode) {
      document.documentElement.classList.add("dark");
      // setToggleBtn('<i class="far fa-sun"></i>Light Mode');
      setToggleBtn('<i class="fas fa-moon"></i> Dark Mode');

      setMode((current) => (current = !current));
    }
    if (!mode) {
      document.documentElement.classList.remove("dark");
      setToggleBtn('<i class="far fa-sun"></i>Light Mode');

      // setToggleBtn('<i class="fas fa-moon"></i> Dark Mode');
      setMode((current) => (current = !current));
    }
  };

  //hacer aparecer boton wpp

  const aparecerBtn = () => {
    //console.log(window.scrollY); //como verifico si hace scroll la pagina
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", aparecerBtn);

  return (
    <div className="flex w-full justify-between items-center h-[100px] px-4  z-10 dark:text-black text-white  drop-shadow-2xl dark:bg-gray-100 bg-black fixed lg:px-[3rem] sm:px-[1.5rem]">
      <div className="py-3">
        <img
          src={logoNav}
          alt="/"
          className={logo ? "hidden" : "block hover:cursor-pointer"}
        />
        {/* <img src={logoNav} alt="/" className="hover:cursor-pointer" /> */}
      </div>

      <ul className="hidden md:flex gap-x-4 items-center">
        <li>
          <Link to="inicio" smooth={true} offset={-200} duration={500}>
            Inicio
          </Link>
        </li>
        <li>
          <Link to="aboutUs" smooth={true} duration={500}>
            ¿Quienés somos?
          </Link>
        </li>
        <li>
          <Link to="preguntasFrecuentes" smooth={true} duration={500}>
            Preguntas Frecuentes
          </Link>
        </li>
        <a
          href="https://wa.me/5493547543969"
          class="btn-wsp"
          target="_blank"
          rel="noreferrer"
          className="px-4 py-3"
        >
          <li>
            <button className="rounded-md border border-white text-white px-4 py-3 hover:bg-white btn-hablanos">
              ¡Hablanos!
            </button>
          </li>
        </a>
      </ul>

      {/* Hamburger */}
      <div className="md:hidden cursor-pointer z-10" onClick={handleNav}>
        {nav ? (
          <AiOutlineClose size={20} className="text-white" />
        ) : (
          <HiOutlineMenuAlt4 className="" size={20} />
        )}
      </div>

      {/* Mobile menu dropdown */}
      <div
        className={
          nav
            ? "absolute text-white left-0 top-0 w-full bg-black px-4 py-7 flex flex-col"
            : "absolute left-[-100%]"
        }
        onClick={handleNav}
      >
        <ul>
          <div className="flex items-center">
            <img
              src={logoNav}
              alt="/"
              className="lg:h-[40px] md:h-[30px] sm:h-[90px]  mr-2"
            />
          </div>
          <Link to="inicio" smooth={true} offset={100} duration={500}>
            <li
              className="border-b-2 border-zinc-300 w-full mt-8 p-4 hover:text-white"
              onClick={handleNav}
            >
              Inicio
            </li>
          </Link>
          <Link to="aboutUs" smooth={true} offset={100} duration={200}>
            <li
              className="border-b-2 border-zinc-300 w-full mt-8 p-4 "
              onClick={handleNav}
            >
              ¿Quienés somos?
            </li>
          </Link>
          <Link
            to="preguntasFrecuentes"
            smooth={true}
            offset={-200}
            duration={500}
          >
            <li
              className="border-b-2 border-zinc-300 w-full mt-8 p-4 "
              onClick={handleNav}
            >
              Preguntas recuentes
            </li>
          </Link>
          <Link to="inicio" smooth={true} offset={100} duration={200}>
            <button className="py-3 px-6 my-4 text-white hover:bg-white btn-hablanos border-2 shadow-lg   max-w-[150px] rounded-md">
              ¡Hablanos!
            </button>
          </Link>

          <div className="flex text-center my-6 mt-8 px-4 space-x-3">
            <a
              href="https://www.facebook.com/Qui%C3%B1onero-Hijos-SRL-103407112576918"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="/" className="iconos" />
            </a>
            <a
              href="https://www.instagram.com/quinioneroehijos/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} alt="/" className="iconos" />
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
