import React from "react";
import { Link } from "react-scroll";
import logoNav from "../assets/Navbar/Logo.png";
import facebook from "../assets/Footer/facebook.png";
import insta from "../assets/Footer/instagram.png";

const Footer = () => {
  return (
    <div className="flex-col w-full space-y-3 text-white  bg-black  ">
      <div className="flex justify-between w-full items-center lg:px-[3rem] sm:px-[1.5rem] space-x-4">
        <div className="py-3">
          <img src={logoNav} alt="/" />
        </div>
        {/* <div className="space-x-1 flex flex-col items-center space-y-3">
          <h4 className="lg:text-[16px] md:text-[16px] sm:text-[12px]">
            Redes
          </h4>
          <div className="flex gap-x-2">
            <img src={facebook} alt="/" className="iconos" />
            <img src={insta} alt="/" className="iconos" />
          </div>
        </div> */}
        <ul className="md:flex gap-x-4 items-center">
          <li className="text-[16px] sm:text-[12px]">
            <Link to="inicio" smooth={true} offset={-200} duration={500}>
              Inicio
            </Link>
          </li>
          <li className="text-[16px] sm:text-[12px]">
            <Link to="aboutUs" smooth={true} duration={500}>
              ¿Quienés somos?
            </Link>
          </li>
          <li className="text-[16px] sm:text-[12px]">
            <Link to="preguntasFrecuentes" smooth={true} duration={500}>
              Preguntas Frecuentes
            </Link>
          </li>
        </ul>
      </div>
      <hr className="w-full text-white" />
      <div className="flex justify-between w-full items-center lg:px-[3rem] sm:px-[1rem]">
        <div className="py-3">
          <p className="text-[16px] sm:text-[12px]">
            ©2022 Quiñonero & Hijos S.R.L | Todos los derechos reservados
          </p>
        </div>
        {/* <div className="py-3 space-x-1 flex">
          <img src={facebook} alt="/" className="iconos" />
          <img src={insta} alt="/" className="iconos" />
        </div> */}
        <div className="flex gap-x-2 mx-6">
          <a
            href="https://www.facebook.com/Qui%C3%B1onero-Hijos-SRL-103407112576918"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="/" className="iconos" />
          </a>
          <a
            href="https://www.instagram.com/quinioneroehijos/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="/" className="iconos" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
