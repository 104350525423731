import React from "react";

const Whatsapp = () => {
  return (
    <a
      //   href="https://api.whatsapp.com/send?phone=+5493547522687"
      href="https://wa.me/5493547543969"
      class="btn-wsp"
      target="_blank"
      rel="noreferrer"
    >
      <i class="fa fa-whatsapp icono"></i>
    </a>
  );
};

export default Whatsapp;
