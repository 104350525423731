import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Whatsapp from "./components/Whatsapp";
import AboutUs from "./Pages/AboutUs";
import Hero from "./Pages/Hero";
import PreguntasFrecuentes from "./Pages/PreguntasFrecuentes";
import Security from "./Pages/Security";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <AboutUs />
      <Security />
      <PreguntasFrecuentes />
      <Whatsapp />
      <Footer />
    </div>
  );
}

export default App;
