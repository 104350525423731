import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import aboutUs from "../assets/AboutUs/Casa.png";
// import aboutUs from "../assets/AboutUs/AboutUs.png";
import "../style.css";
const AboutUs = () => {
  return (
    <div name="aboutUs" className=" text-black ">
      <div className="grid lg:grid-cols-2 h-[80%]">
        <div className="lg:pt-[96px] md:pt-[96px] sm:pt-[70px] mx-auto lg:px-[6rem] sm:px-[1rem] space-y-8">
          <h1 className="font-sans lg:text-[30px] md:text-[30px] sm:text-[20px] font-bold">
            ¿Quiénes somos?
          </h1>
          <hr className="my-4" />
          <div className="space-y-8 lg:text-[16px] md:text-[16px] sm:text-[12px] pb-4">
            <p>
              Somos una empresa vinculada al sector inmobiliario que, bajo el
              seguimiento y respaldo de abogados, le brindamos la posibilidad de
              contar con recibos de sueldo reales, seguros, sin seven ni veraz,
              a aquellas personas que necesiten alquilar un local y/o inmueble.
            </p>
            <p>
              Al mismo tiempo, ofrecemos la posibilidad, a quienes deseen
              obtener ganancias extras, de trabajar su recibo de sueldo.
            </p>
          </div>
        </div>
        <img
          src={aboutUs}
          alt=""
          className=" w-full lg:h-[80vh] md:h-[80vh] sm:h-[50vh]"
        />
      </div>
    </div>
  );
};

export default AboutUs;
